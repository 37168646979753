<template>
<div class="wrap">
    <div class="mg-auto">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> 기업 등록</h2>

            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>기업명 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="name" class="ml-20 w-200px">
                                </td>

                                <th>기업코드<span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model.trim="companyCode"  class="ml-20 w-200px" maxlength="3"/>
                                    <button type="button" class="default-btn ml-20" @click="isVailCompanyCd()">중복체크</button><br>
                                    <span class="ml-20"> 기업코드는 영문 대문자/숫자 3자리로 입력해 주세요. (B2C 입력 불가)</span>
                                </td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <td class="left">
                                    <input type="text" v-model="manager" class="ml-20 w-200px">
                                </td>
                                <th>연락처</th>
                                <td class="left">
                                    <input type="text" v-model="phone1" class="ml-20 w-50px"><span class="ml-10">-</span>
                                    <input type="text" v-model="phone2" class="ml-10 w-50px"><span class="ml-10">-</span>
                                    <input type="text" v-model="phone3" class="ml-10 w-50px">
                                </td>
                            </tr>
                            <tr>
                                <th>담당자 Email</th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="managerEmail" class="ml-20 w-200px">
                                </td>
                            </tr>
                            <tr>
                                <th>기업메모</th>
                                <td class="left" colspan="3">
                                    <textarea v-model="memo" class="ml-20 h-100px w-98per" value="" />
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle mr-10 pointer" @click="submit()">등록</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetFormatToday
} from '@/utils/common.js'
export default {
    data: () => ({
        name: '', // 기업명
        manager: '', // 담당자
        phone1: '', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        memo: '', // 기업메모
        companyCode:'',//기업코드
        managerEmail: '', //매니저 이메일
        companyRegExp:true, //기업코드 정규표현식 확인 여부
        isVaildCompanyCode: false, //사용가능 기업코드 여부
        btnDisabled:false, //등록버튼 활성화/비활성화 여부
    }),

    mounted () {
        // 계약일자-오늘 날짜를 default로
        this.consStartDate = GetFormatToday()
        this.consEndDate = GetFormatToday()
    },

    watch:{
        companyCode(val){
            /* 새로 입력하면 무조건 중복체크 false */
            this.isVaildCompanyCode = false;

            let regEngNum = /^[A-Z0-9]{3}$/;
            this.companyRegExp = regEngNum.test(val);

            /* B2C 문자열을 포함여부 */
            if(this.companyRegExp){
                if(val === 'B2C'){
                    this.companyRegExp = false;
                }
            }

            this.companyCode = this.companyCode.toUpperCase();
        }
    },

    methods: {
        // 등록
        submit() {
             // 기업명 검사
            if (!EmptyCheck(this.name, '기업명을')) {
                return false
            }

            /** 기업코드 빈값여부 */
            if(!EmptyCheck(this.companyCode,'기업코드를')){
                return false
            }

            /* 기업코드 정규표현식 여부 */
            if(!this.companyRegExp){
                alert('기업코드는 영문 대문자/숫자 3자리로 입력해 주세요. (B2C 입력 불가)');
                return false;
            }

            /* 중복확인 여부 */
            if(!this.isVaildCompanyCode){
                alert('기업 코드 중복 확인을 해주세요.');
                return false;
            }

            let phone = '';
            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone2 !== '' && this.phone3 !== '') {
                phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                phone = ''
            }

            var vo = {
                companyCode : this.companyCode,
                name: this.name,
                manager: this.manager,
                phone: phone,
                memo: this.memo,
                managerEmail: this.managerEmail,
            }
            if(this.btnDisabled){
                alert('저장중입니다. 잠시만 기다려주세요.');
                return false;
            }

            this.btnDisabled = true;
            this.axios.post('/api/v1/company', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        // alert('기업등록이 완료되었습니다.')
                        this.$router.push({ name: 'origin-companyContractRegister', query: { crmCompanyIdx: res.data.idx } })
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(()=>{
                    this.btnDisabled = false;
                })
        },

        // 이전 페이지로 이동
        cancel() {
            this.$router.push('/origin/company_manage')
        },

        /**
         * 기업 코드 중복체크
         */
        isVailCompanyCd(){
            /**
             * 1. 입력하지 않을 경우 alert
             * 2. 사용가능한 코드 일 경우 사용가능 하다는 alert 창
             * 3. 중복일 경우 처리 필요
             */
            if(!this.companyCode){
                alert('기업코드를 입력해주세요');
                return false;
            }
            /* 정규표현식 확인 여부 */
            if(!this.companyRegExp){
                alert('기업코드는 영문 대문자/숫자 3자리로 입력해 주세요. (B2C 입력 불가)');
                this.companyCode = '';
                return false;
            }
            this.axios.get(`/api/v1/company/company_code/${this.companyCode}`,{

            }).then((response)=>{
                const {result} = response.data;
                if(result === 'OK'){
                    this.isVaildCompanyCode = true;
                    alert('사용가능한 코드입니다');
                }else{
                    this.isVaildCompanyCode = false;
                    alert('사용 중인 코드입니다. 다른 코드를 입력해 주세요.');
                    this.companyCode='';
                }
            }).catch(err => {
                console.log(err)
            }).finally(()=>{
            })
        }
    }
}
</script>